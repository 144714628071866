import {Layout} from 'antd';
import OrganisationLogo from '../organisation/Logo';
import {getProtectedRouteConfig} from '../../libs/routes';
import Menu from './Menu';
import './sidebar.less';
import {useState} from 'react';

const protectedRoutes = getProtectedRouteConfig();

/**
 * Sidebar
 */
export default function Sidebar() {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <Layout.Sider
            className="sidebar"
            breakpoint="md"
            theme="light"
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            collapsedWidth={0}
            width={230}
        >
            <OrganisationLogo />
            <Menu routes={protectedRoutes} />
        </Layout.Sider>
    );
}
