import {ApolloError, useMutation} from '@apollo/client';
import {useHistory} from 'react-router';
import LoginMutation from './mutation';
import ProfileQuery from '../profile/query';
import {Login, LoginVariables} from '../../../types/graphql';
import {LS_KEY_ORGANISATION} from '../../libs/auth/constants';

type ReturnType = {
    loading: boolean;
    error?: ApolloError;
    onSubmit: (variables: LoginVariables) => Promise<void>;
};

/**
 * Login hook
 */
export default function useLogin(): ReturnType {
    const history = useHistory();
    const [login, {error, loading}] = useMutation<Login, LoginVariables>(LoginMutation, {
        refetchQueries: [{query: ProfileQuery}],
    });

    /**
     * Handle on submit
     */
    async function handleOnSubmit(variables: LoginVariables) {
        try {
            const {errors} = await login({variables});

            if (errors?.length) {
                throw new Error(errors[0].message);
            }

            localStorage.setItem(LS_KEY_ORGANISATION, variables.organisation);
            history.push('/');
        } catch (err) {}
    }

    return {
        loading,
        error,
        onSubmit: handleOnSubmit,
    };
}
