import {ReactNode} from 'react';
import {ConfigProvider} from 'antd';
import nl_NL from 'antd/lib/locale/nl_NL';
import {BrowserRouter as Router} from 'react-router-dom';
import {ApolloProvider} from '@apollo/client/react';
import {client} from '../../libs/graphql/client';
import 'moment/locale/nl';

type Props = {
    children: ReactNode;
};

/**
 * App shell
 */
export default function AppShell({children}: Props) {
    return (
        <ConfigProvider locale={nl_NL}>
            <ApolloProvider client={client}>
                <Router>{children}</Router>
            </ApolloProvider>
        </ConfigProvider>
    );
}
