import {ApolloClient, InMemoryCache, HttpLink, from} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {LS_KEY_ORGANISATION} from '../auth/constants';

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
        graphQLErrors.map(({message, locations, path}) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    credentials: 'include',
});

const organisationLink = setContext((_, {headers}) => {
    const organisation = localStorage.getItem(LS_KEY_ORGANISATION) || '';

    return {
        headers: {
            ...headers,
            organisation,
        },
    };
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, organisationLink, httpLink]),
    defaultOptions: {
        mutate: {errorPolicy: 'all'},
        query: {errorPolicy: 'all', fetchPolicy: 'cache-first'},
        watchQuery: {errorPolicy: 'all'},
    },
});
