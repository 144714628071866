import {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Spin} from 'antd';
import ProtectedRoute from '../routes/ProtectedRoute';
import {getProtectedRouteConfig, getPublicRouteConfig} from '../../libs/routes';
import './main.less';

/**
 * Main content
 */
export default function Main() {
    const protectedRoutes = getProtectedRouteConfig();
    const publicRoutes = getPublicRouteConfig();

    return (
        <main className="main">
            <Suspense fallback={<Spin />}>
                <Switch>
                    {Object.entries(publicRoutes).map(([key, value]) => (
                        <Route path={key} key={key} component={value.component as any} exact={value.exact} />
                    ))}
                    {Object.entries(protectedRoutes).map(([key, value]) => (
                        <ProtectedRoute key={key} {...value} path={key} />
                    ))}
                </Switch>
            </Suspense>
        </main>
    );
}
