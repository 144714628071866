import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import AppShell from './components/app/Shell';

ReactDOM.render(
    <React.StrictMode>
        <AppShell>
            <App />
        </AppShell>
    </React.StrictMode>,
    document.getElementById('root'),
);
