import {Redirect, Route} from 'react-router';
import {Spin} from 'antd';
import useProfile from '../../../hooks/profile';
import {RouteType} from '../../../libs/routes';

type Props = RouteType & {path: string};

/**
 * Route is protected, user needs to login to see this
 */
export default function ProtectedRoute(props: Props) {
    const {profile, loading} = useProfile();

    if (loading) return <Spin />;
    if (!profile) return <Redirect to="/login" />;

    return <Route exact={props.exact} component={props.component as any} path={props.path} />;
}
