import {ApolloError, FetchResult, useMutation} from '@apollo/client';
import RegisterMutation from './mutation';
import {Register, RegisterVariables} from '../../../types/graphql';

type ReturnType = {
    onSubmit: (variables: Variables) => Promise<FetchResult<Register>>;
    error?: ApolloError;
    loading: boolean;
};

type Variables = RegisterVariables & {organisation: 'existing' | 'new'};

/**
 * Register hook
 */
export default function useRegister(): ReturnType {
    const [register, {error, loading}] = useMutation<Register, RegisterVariables>(RegisterMutation);

    /**
     * Submit handler
     */
    async function handleOnSubmit(variables: Variables) {
        const {organisationName, organisation, ...restVars} = variables;
        const vars = {...restVars, organisationName: ''};

        if (organisation === 'existing') vars.organisationSlug = organisationName;
        else if (organisationName) vars.organisationName = organisationName;

        const result = await register({variables: vars});

        if (result.errors?.length) {
            throw new Error(result.errors[0].message);
        }

        return result;
    }

    return {
        onSubmit: handleOnSubmit,
        error,
        loading,
    };
}
