import {useQuery} from '@apollo/client';
import LOGO_QUERY from './query';
import './logo.less';
import {OrganisationLogo as Query, OrganisationLogoVariables as Variables} from '../../../../types/graphql';
import {LS_KEY_ORGANISATION} from '../../../libs/auth/constants';
import {Spin} from 'antd';

/**
 * Logo for an organisation
 */
export default function OrganisationLogo() {
    const {data, loading} = useQuery<Query, Variables>(LOGO_QUERY, {
        variables: {slug: localStorage.getItem(LS_KEY_ORGANISATION) || ''},
    });

    if (loading) {
        return (
            <div className="organisation__spinner">
                <Spin />
            </div>
        );
    }

    return data?.organisation?.logo ? (
        <div className="organisation__logo">
            <img src={data.organisation.logo} alt="Logo" />
        </div>
    ) : null;
}
