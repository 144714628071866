import {Fragment, useState} from 'react';
import {Input, Typography, Radio} from 'antd';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import Form, {Item} from '../../form';
import useRegister from '../../../hooks/register';
import './form.less';
import {routes} from '../../../libs/routes';

const {Group} = Radio;
const {Paragraph, Link, Text} = Typography;
const {Password} = Input;

type Props = {
    organisationSlug?: string;
};

/**
 * Register form
 */
export default function RegisterForm({organisationSlug = ''}: Props) {
    const [organisationName, setOrganisationName] = useState(organisationSlug);
    const [organisationType, setOrganisationType] = useState(organisationSlug ? 'existing' : '');
    const {onSubmit, error} = useRegister();
    const history = useHistory();
    /**
     * On submit handler
     */
    async function handleOnSubmit(values: any) {
        try {
            await onSubmit(values);
            history.push(routes.home);
        } catch (err) {
            console.log({err});
        }
    }

    return (
        <Fragment>
            <Form
                onSubmit={handleOnSubmit}
                onError={console.log}
                initialValues={{organisationName, organisation: organisationType}}
                buttonLabel="Registreren"
            >
                <Item
                    label="Organisatie"
                    name="organisation"
                    tooltip="Als je je aan wil sluiten bij een bestaande organisatie, vraag dan de beheerder om een uitnodigingslink. Als je een nieuwe organisatie aan wil maken vul je hier een naam in."
                    rules={[{required: true, message: 'Het opgeven van een organisatie is verplicht'}]}
                >
                    <Group
                        options={[
                            {label: 'Bestaand', value: 'existing'},
                            {label: 'Nieuw', value: 'new'},
                        ]}
                        onChange={(e) => setOrganisationType(e.target.value)}
                        optionType="button"
                    />
                </Item>
                {organisationType ? (
                    <Item
                        label="Naam organisatie"
                        name="organisationName"
                        rules={[{required: true, message: 'Het opgeven van een organisatie naam is verplicht'}]}
                    >
                        <Input onChange={(e) => setOrganisationName(e.target.value)} />
                    </Item>
                ) : null}
                <Item label="E-mail" name="email" rules={[{required: true, message: 'E-mail is verplicht'}]}>
                    <Input placeholder="john.doe@email.com" />
                </Item>
                <Item label="Wachtwoord" name="password" rules={[{required: true, message: 'Wachtwoord is verplicht'}]}>
                    <Password placeholder="password" />
                </Item>
                {error?.message ? (
                    <Item>
                        <Text type="danger">{error.message}</Text>
                    </Item>
                ) : null}
            </Form>
            <Item className="actions">
                <Paragraph>
                    Al geregistreerd?
                    <RouterLink to="/login" component={Link}>
                        Login
                    </RouterLink>
                </Paragraph>
            </Item>
        </Fragment>
    );
}
