import {ApolloError, ApolloQueryResult, useQuery} from '@apollo/client';
import ProfileQuery from './query';
import {Profile as Query, Profile_profile as Profile} from '../../../types/graphql';

type ReturnType = {
    profile?: Profile | null;
    loading: boolean;
    error?: ApolloError;
    refetch: () => Promise<ApolloQueryResult<Query>>;
};

/**
 * Profile hook
 */
export default function useProfile(): ReturnType {
    const {data, loading, error, refetch} = useQuery<Query>(ProfileQuery);

    return {
        profile: data?.profile,
        loading,
        error,
        refetch,
    };
}
