import {gql} from '@apollo/client';

export default gql`
    mutation Login($email: String!, $password: String!, $organisation: String!) {
        login(email: $email, password: $password, organisation: $organisation) {
            id
            email
            name
            organisations {
                id
                name
                slug
            }
        }
    }
`;
