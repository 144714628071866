import {Row, Col, Typography, Spin} from 'antd';
import {Redirect} from 'react-router';
import LoginForm from '../../components/login/Form';
import {routes} from '../../libs/routes';
import useProfile from '../../hooks/profile';
import {ReactComponent as LoginSvg} from './img/login.svg';
import './login.less';

const {Title} = Typography;

/**
 * Login page
 */
export default function LoginPage() {
    const {profile, loading} = useProfile();

    if (loading) return <Spin />;
    if (profile) return <Redirect to={routes.home} from={routes.login} />;

    return (
        <div className="login">
            <Row>
                <Col span={10} className="login__left">
                    <LoginSvg />
                </Col>
                <Col span={14} className="login__right">
                    <Title level={2}>Log in</Title>
                    <LoginForm />
                </Col>
            </Row>
        </div>
    );
}
