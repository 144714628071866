import {Row, Col, Typography, Spin} from 'antd';
import {Redirect, useParams} from 'react-router';
import RegisterForm from '../../components/register/Form';
import {routes} from '../../libs/routes';
import useProfile from '../../hooks/profile';
import {ReactComponent as RegisterSvg} from './img/register.svg';
import './register.less';

const {Title} = Typography;

/**
 * Register page
 */
export default function RegisterPage() {
    const {organisationSlug} = useParams<{organisationSlug?: string}>();
    const {profile, loading} = useProfile();

    if (loading) return <Spin />;
    if (profile) return <Redirect to={routes.home} />;

    return (
        <div className="register">
            <Row>
                <Col span={14} className="register__left">
                    <Title level={2}>Registreren</Title>
                    <RegisterForm organisationSlug={organisationSlug} />
                </Col>
                <Col span={10} className="register__right">
                    <RegisterSvg />
                </Col>
            </Row>
        </div>
    );
}
