import {gql} from '@apollo/client';

export default gql`
    mutation Register($email: String!, $password: String!, $organisationSlug: String, $organisationName: String) {
        register(
            email: $email
            password: $password
            organisationSlug: $organisationSlug
            organisationName: $organisationName
        ) {
            id
            name
            email
            organisations {
                id
                name
                slug
            }
        }
    }
`;
