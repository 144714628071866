import {Input, Typography} from 'antd';
// import {Link as RouterLink} from 'react-router-dom';
import Form, {Item} from '../../form';
import useLoginForm from '../../../hooks/login';
import {LS_KEY_ORGANISATION} from '../../../libs/auth/constants';
import {Fragment} from 'react';
import './form.less';

// const {Paragraph, Link, Text} = Typography;
const {Text} = Typography;
const {Password} = Input;

/**
 * Login form
 */
export default function LoginForm() {
    const {onSubmit, error} = useLoginForm();

    return (
        <Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={{organisation: localStorage.getItem(LS_KEY_ORGANISATION)}}
                buttonLabel="Inloggen"
            >
                <Item
                    label="Organisatie"
                    name="organisation"
                    rules={[{required: true, message: 'Organisatie is verplicht'}]}
                >
                    <Input placeholder="organisatie" />
                </Item>
                <Item label="E-mail" name="email" rules={[{required: true, message: 'E-mail is verplicht'}]}>
                    <Input placeholder="john.doe@email.com" />
                </Item>
                <Item label="Wachtwoord" name="password">
                    <Password placeholder="password" />
                </Item>
                {error?.message ? (
                    <Item>
                        <Text type="danger">{error.message}</Text>
                    </Item>
                ) : null}
            </Form>
            {/* <Item className="actions">
                <Paragraph>
                    <Text>Nog niet geregistreerd?</Text>
                    <RouterLink to="/register" component={Link}>
                        Registreren
                    </RouterLink>
                </Paragraph>
            </Item> */}
        </Fragment>
    );
}
