import {Menu as AntdMenu} from 'antd';
import {Link, useLocation} from 'react-router-dom';
import {RoutesType} from '../../../libs/routes';

const {SubMenu, Item: MenuItem} = AntdMenu;

type Props = {
    routes: RoutesType;
};

/**
 * Menu
 */
export default function Menu({routes}: Props) {
    const location = useLocation();

    return (
        <AntdMenu selectedKeys={[location?.pathname]} mode="inline">
            {Object.entries(routes).map(([url, value]) => {
                const Icon = value.icon;

                if (value.isMenuItem && value.children?.length) {
                    return (
                        <SubMenu key={url} icon={<Icon />} title={value.label}>
                            {value.children.map((childKey) => {
                                const child = routes[childKey];
                                if (!child) return null;

                                const Icon = child.icon;
                                return (
                                    <MenuItem key={childKey} icon={<Icon />}>
                                        <Link to={childKey}>{child.label}</Link>
                                    </MenuItem>
                                );
                            })}
                        </SubMenu>
                    );
                }

                return value.isMenuItem && !value.parent ? (
                    <MenuItem key={url} icon={<Icon />}>
                        <Link to={url}>{value.label}</Link>
                    </MenuItem>
                ) : null;
            })}
        </AntdMenu>
    );
}
