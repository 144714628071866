import {ReactNode, useState} from 'react';
import {Form as AntdForm, Button} from 'antd';
import {Store} from 'rc-field-form/lib/interface';
import './form.less';
import {FormInstance} from 'antd/lib/form/Form';

export const {Item, List} = AntdForm;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

type Props = {
    children: ReactNode;
    onSubmit: (values: any) => Promise<void>;
    onError?: (err?: Error) => void;
    initialValues?: Store;
    buttonLabel: string;
    form?: FormInstance<any>;
};

/**
 * Generic form handler
 */
export default function Form({children, onSubmit, onError, initialValues, buttonLabel, form}: Props) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    /**
     * Submit handler
     */
    async function handleOnSubmit(values: any) {
        try {
            setIsSubmitting(true);
            await onSubmit(values);
        } catch (err) {
            handleOnError(err);
        } finally {
            setIsSubmitting(false);
        }
    }

    /**
     * On error handler
     */
    function handleOnError(err?: Error) {
        onError?.(err);
    }

    return (
        <AntdForm {...layout} layout="vertical" onFinish={handleOnSubmit} initialValues={initialValues} form={form}>
            {children}
            <Item>
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                    {buttonLabel}
                </Button>
            </Item>
        </AntdForm>
    );
}
