import {gql} from '@apollo/client';

export default gql`
    query Profile {
        profile {
            id
            name
            email
            organisations {
                id
                name
                slug
            }
        }
    }
`;
