import {lazy, LazyExoticComponent, ReactNode} from 'react';
import {
    UserOutlined,
    DashboardOutlined,
    TeamOutlined,
    PlusCircleOutlined,
    FormOutlined,
    LogoutOutlined,
    CalendarOutlined,
} from '@ant-design/icons';
import LoginPage from '../../pages/Login';
import RegisterPage from '../../pages/Register';

export type RouteKey = keyof typeof routes;
export type RoutesType = {[key: string]: RouteType};
export type RouteType = {
    label: string;
    icon: any;
    exact: boolean;
    component: ReactNode | LazyExoticComponent<(props: any) => JSX.Element>;
    isMenuItem: boolean;
    children?: Array<string>;
    parent?: Array<string>;
    adminOnly: boolean;
};

export const routes = {
    home: '/',
    units: '/teams',
    singleUnit: '/team/:id',
    createUnit: '/teams/create',
    issued: '/issued',
    singleIssued: '/issued/detail/:id',
    createIssued: '/issued/create',
    editIssued: '/issued/edit/:id',
    login: '/login',
    register: '/register',
    registerInvite: '/register/:organisationSlug',
    createDynamicIssuedType: '/type/create',
    editDynamicIssuedType: '/type/edit/:id',
    createSeason: '/season/create',
    season: '/season',
    dynamicIssuedTypes: '/types',
    singleContact: '/contact/edit/:slug',
    createContact: '/contact/create',
    contacts: '/contacts',
};

/**
 * Get protected routes
 */
export function getProtectedRouteConfig(): RoutesType {
    return {
        [routes.home]: {
            label: 'Dashboard',
            icon: DashboardOutlined,
            exact: true,
            isMenuItem: true,
            component: lazy(() => import('../../pages/Home')),
            adminOnly: false,
        },
        [routes.issued]: {
            label: 'Uitgiftes',
            icon: LogoutOutlined,
            exact: true,
            isMenuItem: true,
            children: [routes.issued, routes.createIssued],
            component: lazy(() => import('../../pages/Issued')),
            adminOnly: false,
        },
        [routes.createIssued]: {
            label: 'Uitgifte aanmaken',
            icon: PlusCircleOutlined,
            exact: true,
            isMenuItem: true,
            parent: [routes.issued],
            component: lazy(() => import('../../pages/Issued/create')),
            adminOnly: true,
        },
        [routes.editIssued]: {
            label: 'Uitgifte bewerken',
            icon: PlusCircleOutlined,
            exact: true,
            isMenuItem: false,
            parent: [routes.issued],
            component: lazy(() => import('../../pages/Issued/edit')),
            adminOnly: true,
        },
        [routes.dynamicIssuedTypes]: {
            label: 'Dynamische types',
            icon: FormOutlined,
            exact: true,
            isMenuItem: true,
            component: lazy(() => import('../../pages/DynamicIssuedType')),
            children: [routes.dynamicIssuedTypes, routes.createDynamicIssuedType],
            adminOnly: true,
        },
        [routes.createDynamicIssuedType]: {
            label: 'Aanmaken',
            icon: PlusCircleOutlined,
            exact: true,
            isMenuItem: true,
            parent: [routes.dynamicIssuedTypes],
            component: lazy(() => import('../../pages/DynamicIssuedType/create')),
            adminOnly: true,
        },
        [routes.editDynamicIssuedType]: {
            label: 'Bewerken',
            icon: DashboardOutlined,
            exact: true,
            isMenuItem: false,
            parent: [routes.dynamicIssuedTypes],
            component: lazy(() => import('../../pages/DynamicIssuedType/edit')),
            adminOnly: true,
        },
        [routes.singleIssued]: {
            label: 'Uitgifte',
            icon: DashboardOutlined,
            exact: true,
            isMenuItem: false,
            parent: [routes.dynamicIssuedTypes],
            component: lazy(() => import('../../pages/DynamicIssuedType/edit')),
            adminOnly: true,
        },
        [routes.units]: {
            label: 'Teams',
            icon: TeamOutlined,
            exact: true,
            isMenuItem: true,
            component: lazy(() => import('../../pages/Unit')),
            children: [routes.units, routes.createUnit],
            adminOnly: false,
        },
        [routes.createUnit]: {
            label: 'Team aanmaken',
            icon: PlusCircleOutlined,
            exact: true,
            isMenuItem: true,
            parent: [routes.units],
            component: lazy(() => import('../../pages/Unit/create')),
            adminOnly: true,
        },
        [routes.singleUnit]: {
            label: 'Team',
            icon: DashboardOutlined,
            exact: true,
            isMenuItem: false,
            parent: [routes.dynamicIssuedTypes],
            component: lazy(() => import('../../pages/Unit/edit')),
            adminOnly: true,
        },
        [routes.contacts]: {
            label: 'Contactpersonen',
            icon: UserOutlined,
            exact: true,
            isMenuItem: true,
            children: [routes.contacts, routes.createContact],
            component: lazy(() => import('../../pages/Contact')),
            adminOnly: true,
        },
        [routes.createContact]: {
            label: 'Contactpersoon aanmaken',
            icon: PlusCircleOutlined,
            exact: true,
            isMenuItem: true,
            parent: [routes.contacts],
            component: lazy(() => import('../../pages/Contact/create')),
            adminOnly: true,
        },
        [routes.singleContact]: {
            label: 'Contactpersoon',
            icon: DashboardOutlined,
            exact: true,
            isMenuItem: false,
            parent: [routes.contacts],
            component: lazy(() => import('../../pages/Contact/edit')),
            adminOnly: false,
        },
        [routes.season]: {
            label: 'Seizoen',
            icon: CalendarOutlined,
            exact: true,
            isMenuItem: true,
            component: lazy(() => import('../../pages/Season')),
            adminOnly: true,
            children: [routes.season, routes.createSeason],
        },
        [routes.createSeason]: {
            label: 'Seizoen aanmaken',
            icon: PlusCircleOutlined,
            exact: true,
            isMenuItem: true,
            parent: [routes.season],
            component: lazy(() => import('../../pages/Season/create')),
            adminOnly: true,
        },
    };
}

/**
 * Get public routes
 */
export function getPublicRouteConfig(): RoutesType {
    return {
        [routes.login]: {
            label: 'Login',
            icon: DashboardOutlined,
            exact: true,
            isMenuItem: false,
            component: LoginPage,
            adminOnly: false,
        },
        [routes.register]: {
            label: 'Register',
            icon: DashboardOutlined,
            exact: true,
            isMenuItem: false,
            component: RegisterPage,
            adminOnly: false,
        },
        [routes.registerInvite]: {
            label: 'Register',
            icon: DashboardOutlined,
            exact: true,
            isMenuItem: false,
            component: RegisterPage,
            adminOnly: false,
        },
    };
}
