import {Layout} from 'antd';
import {useLocation, matchPath} from 'react-router';
import useProfile from '../../hooks/profile';
import Main from '../main';
import {getProtectedRouteConfig} from '../../libs/routes';
import Sidebar from '../sidebar';

import './app.less';

const protectedRoutes = getProtectedRouteConfig();
const keys = Object.keys(protectedRoutes);

/**
 * Main app
 */
export default function App() {
    const {profile} = useProfile();
    const {pathname} = useLocation();

    /**
     * Check if we should show the sidebar
     */
    function showSidebar() {
        return profile && keys.some((key) => matchPath(pathname, {path: key}));
    }

    return (
        <Layout>
            {profile && showSidebar() ? <Sidebar /> : null}
            <Main />
        </Layout>
    );
}
