import {gql} from '@apollo/client';

export default gql`
    query OrganisationLogo($slug: String!) {
        organisation(slug: $slug) {
            id
            logo
        }
    }
`;
